import baseAPI from "@/http";
const routerTerCategorias = "api/territorios-categorias";

export const territoriosCategoriasAPI = {
  create: (indicador) => baseAPI.post(routerTerCategorias, indicador),
  update: (indicador) => baseAPI.put(routerTerCategorias, indicador),
  delete: (id) => baseAPI.delete(`${routerTerCategorias}/${id}`),
  list: () => baseAPI.get(routerTerCategorias),
  get: (id) => baseAPI.get(`${routerTerCategorias}/${id}`),
};
