import baseAPI from "@/http";
const router = "api/territorios";
export const territoriosAPI = {
  create: (indicador) => baseAPI.post(router, indicador),
  update: (indicador) => baseAPI.put(router, indicador),
  delete: (id) => baseAPI.delete(`${router}/${id}`),
  list: () => baseAPI.get(router),
  get: (id) => baseAPI.get(`${router}/${id}`),
  getByUf: (uf) => baseAPI.post(`${router}/get-territorio-by-uf`, uf),
};
