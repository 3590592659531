<template>
  <ComboboxGenerica
    :label="label"
    :loading="loading"
    :items="items"
    :value="filter.terCategoria"
    :itemValue="'territorioCategoriaId'"
    :itemLabel="'nomeCategoria'"
    :outlined="outlined"
    @change="(e) => (filter.terCategoria = e)"
  ></ComboboxGenerica>
</template>

<script>
import { territoriosCategoriasAPI } from "@/apis/territorios";
import { ComboboxGenerica } from "@/components/form";
export default {
  name: "ComboboxTerCategoria",
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: true },
  },
  components: {
    ComboboxGenerica,
  },
  data() {
    return {
      items: [],
      label: "Categorias",
      loading: false,
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      territoriosCategoriasAPI
        .list()
        .then((resp) => {
          this.items = resp.data;
          this.loading = false;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar categoria");
          this.loading = false;
        });
    },
  },
};
</script>
