<template>
  <div
    style="max-height: 40vh; overflow: hidden; overflow-y: auto"
    class="filtersSettings"
  >
    <div class="filtro-item mt-2">
      <!-- <div v-if="$store.state.uf === 'NA'"> -->
      <UF-combobox :filter="filtro" :outlined="true" />
      <color-picker
        v-if="filtro.uf.length"
        :defaultColor="filtersColor.uf"
        @onChange="(event) => changeMapColor(event, 'uf')"
      ></color-picker>
    </div>
    <div class="filtro-item">
      <div>
        <ComboboxTerritorios
          :filter="filtro"
          :uf="ufString"
        ></ComboboxTerritorios>
      </div>
      <color-picker
        v-if="filtro.territorio.length"
        :defaultColor="filtersColor.region"
        @onChange="(event) => changeMapColor(event, 'region')"
      ></color-picker>
    </div>
    <div class="filtro-item">
      <ComboboxTerStatus :filter="filtro"></ComboboxTerStatus>
      <color-picker
        v-if="filtro.statusTer.length"
        :defaultColor="filtersColor.relacionamento"
        @onChange="(event) => changeMapColor(event, 'relacionamento')"
      ></color-picker>
    </div>
    <div class="filtro-item mt-2">
      <div>
        <ComboboxTerCategoria :filter="filtro"></ComboboxTerCategoria>
      </div>
      <color-picker
        v-if="filtro.terCategoria.length"
        :defaultColor="filtersColor.eixo"
        @onChange="(event) => changeMapColor(event, 'eixo')"
      ></color-picker>
    </div>

    <!-- <div>
      <municipio-combobox :filter="filtro" :outlined="true" />
      <color-picker
        v-if="filtro.municipio.codigoIbge"
        :defaultColor="filtersColor.municipio"
        @onChange="(event) => changeMapColor(event, 'municipio')"
      ></color-picker>
    </div> -->
  </div>
</template>
<script>
import { UFCombobox } from "@/modules/uf";

import { ColorPicker } from "@/components";
import ComboboxTerStatus from "@/modules/territorios/components/ComboboxTerStatus.vue";
import ComboboxTerCategoria from "@/modules/territorios/components/ComboboxTerCategoria.vue";
import ComboboxTerritorios from "@/modules/territorios/components/ComboboxTerritorios.vue";
import { MunicipioCombobox } from "@/modules/municipio";
import { mapGetters, mapActions } from "vuex";
import { territoriosAPI } from "@/apis/territorios";

export default {
  name: "FiltrosTerritorios",
  components: {
    ColorPicker,
    ComboboxTerStatus,
    ComboboxTerCategoria,
    ComboboxTerritorios,
    MunicipioCombobox,
    UFCombobox,
  },
  props: {
    filtersDefaultColor: { type: Object, default: () => {} },
  },
  data() {
    return {
      territorios: [],
      filtro: {
        statusTer: [],
        terCategoria: [],
        territorio: [],
        municipio: {},
        uf: [],
      },
      qtdStatusTer: 0,
      qtdTer: 0,
      qtdTerCategoria: 0,
      qtdMunicipio: 0,
      qtdUf: 0,
      filtersColor: this.filtersDefaultColor,
    };
  },
  computed: {
    ...mapGetters(["mapa", "isSala"]),
    ufString() {
      return this.filtro.uf.length > 0
        ? this.filtro.uf.map((item) => item.uf).join(",")
        : "";
    },
  },
  async mounted() {
    try {
      const territorioResponse = await territoriosAPI.getByUf({ uf: "" });
      this.territorios = territorioResponse.data;
    } catch (error) {
      console.log("🚀 ~ mounted ~ error:", error);
      this.$notification.error("Erro ao carregar a lista de territórios");
    }
  },
  methods: {
    ...mapActions([
      "setMapaVariables",
      "limparMapaFixo",
      "setLoading",
      "resetarMapaFixo",
    ]),
    limpar() {
      this.setLoading(true);
      this.limparMapaFixo();
      this.filtro = {
        statusTer: [],
        terCategoria: [],
        territorio: [],
        municipio: {},
        uf: [],
      };
      this.qtdStatusTer = 0;
      this.qtdTer = 0;
      this.qtdTerCategoria = 0;
      this.qtdMunicipio = 0;
      this.qtdUf = 0;
      this.$forceUpdate();

      this.setLoading(false);
    },
    changeMapColor(event, label) {
      this.filtersColor[label] = event;
      localStorage.setItem(
        "mapFiltersColors",
        JSON.stringify(this.filtersColor)
      );

      this.$emit("changeColor", this.filtersColor);
    },
    filtrarStatus(item) {
      if (this.filtro.statusTer.length > 0) {
        let territoriosComStatus = this.territorios.filter((t) =>
          this.filtro.statusTer.some((s) => t.statusId == s.statusId)
        );

        let ter = item.territorios ? item.territorios.split(",") : "";

        if (this.filtro.uf.length > 0) {
          territoriosComStatus = territoriosComStatus
            .filter((t) => this.filtro.uf.some((v) => v.uf == t.uf))
            .map((item) => item.territoriosId.toString());

          ter =
            item.territorios && this.filtro.uf.some((v) => v.uf == item.uf)
              ? item.territorios.split(",")
              : "";
        } else {
          territoriosComStatus = territoriosComStatus.map((item) =>
            item.territoriosId.toString()
          );
        }

        if (ter && territoriosComStatus.some((item) => ter.includes(item))) {
          item.class = "corRelacionamento";
          this.qtdStatusTer++;
        }
        this.filtrarCategoria(item);
      } else {
        this.filtrarCategoria(item);
      }
    },
    filtrarPorUf(item) {
      if (this.filtro.uf.some((val) => val.uf == item.uf && item.possuiTE)) {
        item.class = "corUF";
        this.qtdUf++;
        this.filtrarTerritorio(item);
      }
    },
    filtrarTerritorio(item) {
      if (this.filtro.territorio.length > 0) {
        let territorios = item.territorios ? item.territorios.split(",") : "";

        if (
          this.filtro.territorio.some(
            (val) =>
              territorios.includes(val.territoriosId.toString()) &&
              item.uf == val.uf
          )
        ) {
          item.class = "corUR";
          this.qtdTer++;
        }

        this.filtrarStatus(item);
      } else {
        this.filtrarStatus(item);
      }
    },
    filtrarCategoria(item) {
      if (this.filtro.terCategoria.length > 0) {
        let categorias =
          item.categorias && item.categorias.length
            ? item.categorias.split(",")
            : "";

        if (
          this.filtro.terCategoria.some((val) =>
            categorias.includes(val.territorioCategoriaId.toString())
          )
        ) {
          item.class = "corEixo";
          this.qtdTerCategoria++;
        }
      }
    },
    filtrarPorMunicipio(item) {
      if (this.filtro.municipio.codigoIbge == item.codigoIBGE) {
        item.class = "corMunicipio";
        this.qtdMunicipio++;
      }
    },
    qtdTerritoriosTotal() {
      let territorios = this.territorios;

      if (this.filtro.uf.length) {
        console.log("erro2");
        territorios = this.territorios.filter((item) =>
          this.filtro.uf.some((val) => val.uf == item.uf)
        );
      }

      if (this.filtro.territorio.length) {
        territorios = territorios.filter((item) => {
          return this.filtro.territorio.some(
            (val) =>
              item.territoriosId == val.territoriosId && item.uf == val.uf
          );
        });
      }

      if (this.filtro.statusTer.length) {
        territorios = territorios.filter((item) =>
          this.filtro.statusTer.some((val) => item.statusId == val.statusId)
        );
      }

      if (this.filtro.terCategoria.length) {
        territorios = territorios.filter((item) => {
          let categorias =
            item.categorias && item.categorias.length
              ? item.categorias.split(",")
              : "";

          return this.filtro.terCategoria.some((val) =>
            categorias.includes(val.territorioCategoriaId.toString())
          );
        });
      }

      return territorios;
    },
    filtrar() {
      if (
        !Object.values(this.filtro).find((item) => item.length > 0) &&
        !this.filtro.municipio.codigoIbge
      ) {
        return;
      }

      this.setLoading(true);
      this.resetarMapaFixo();

      this.auxMapa = this.mapa.mapaFixo;

      this.qtdStatusTer = 0;
      this.qtdTer = 0;
      this.qtdTerCategoria = 0;
      this.qtdMunicipio = 0;
      this.qtdUf = 0;

      this.auxMapa.forEach((item) => {
        if (this.filtro.uf.length) {
          this.filtrarPorUf(item);
        } else if (this.filtro.territorio.length) {
          this.filtrarTerritorio(item);
        } else if (this.filtro.statusTer.length) {
          this.filtrarStatus(item);
        } else if (this.filtro.terCategoria.length) {
          this.filtrarCategoria(item);
        }
        if (this.filtro.municipio) {
          this.filtrarPorMunicipio(item);
        }
      });

      this.setMapaVariables({
        mapaFixo: this.auxMapa,
        totalMapaTerritorio: this.qtdTer,
        totalMapaTerCategoria: this.qtdTerCategoria,
        totalMapaMunicipio: this.qtdMunicipio,
        totalMapaTerStatus: this.qtdStatusTer,
        totalMapaUfs: this.qtdUf,
        qntidadeTerritorioUf: this.qtdTerritoriosTotal(),
        filter: {
          territorio: this.filtro.territorio,
          terCategoria: this.filtro.terCategoria,
          municipio: this.filtro.municipio,
          terStatus: this.filtro.statusTer,
          uf: this.filtro.uf,
        },
      });

      this.setLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.filtro-item {
  padding-bottom: 10px;
}

.eixo {
  position: relative;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.38);
  padding: 10px;
  h1 {
    position: absolute;
    color: rgb(0 0 0 / 41%);
    top: -13px;
    left: 10px;
    background: #fff;
    font-size: 16px;
    padding: 0 10px;
  }
}
</style>
