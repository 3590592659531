import baseAPI from "@/http";

const routerTerStatus = "api/territorios-status";

export const territoriosStatusAPI = {
  create: (indicador) => baseAPI.post(routerTerStatus, indicador),
  update: (indicador) => baseAPI.put(routerTerStatus, indicador),
  delete: (id) => baseAPI.delete(`${routerTerStatus}/${id}`),
  list: () => baseAPI.get(routerTerStatus),
  get: (id) => baseAPI.get(`${routerTerStatus}/${id}`),
};
