import baseAPI from "@/http";
const routerTerResponsaveis = "api/territorios-responsaveis";

export const territoriosResponsaveisAPI = {
  create: (indicador) => baseAPI.post(routerTerResponsaveis, indicador),
  update: (indicador) => baseAPI.put(routerTerResponsaveis, indicador),
  delete: (id) => baseAPI.delete(`${routerTerResponsaveis}/${id}`),
  list: () => baseAPI.get(routerTerResponsaveis),
  get: (id) => baseAPI.get(`${routerTerResponsaveis}/${id}`),
};
