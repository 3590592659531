<template>
  <ComboboxGenerica
    :label="label"
    :loading="loading"
    :items="items"
    :value="filter.statusTer"
    :itemValue="'statusId'"
    :itemLabel="'nome'"
    @change="(e) => (filter.statusTer = e)"
  ></ComboboxGenerica>
</template>

<script>
import { territoriosStatusAPI } from "@/apis/territorios";
import { ComboboxGenerica } from "@/components/form";
export default {
  name: "ComboboxTerStatus",
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  components: {
    ComboboxGenerica,
  },
  data() {
    return {
      items: [],
      label: "Status do Território",
      loading: false,
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      territoriosStatusAPI
        .list()
        .then((resp) => {
          this.items = resp.data;
          this.loading = false;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar status");
          this.loading = false;
        });
    },
  },
};
</script>
